@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");

.sidebar-container {
  height: 100vh;
  max-width: 300px;
}

.body-container {
  height: 100vh;
}

.file-upload-adictic {
  position: relative;
  overflow: hidden;
}

.file-upload-adictic input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
